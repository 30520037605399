import React from "react";
import NavLink from "./Links.js";
import { formatUrl } from "../utils/functions.js";
import { useLocation } from "react-router-dom";

const MenuMobile = ({
  logo,
  logoUrl,
  closeMenuMobile,
  Links,
  phone,
  email,
  address,
  postalCode,
  country,
  socialMedias,
  toggleMenu,
}) => {
  const location = useLocation();

  return (
    <div className="menu-mobile">
      <div className={`menu-mobile__top ${location.pathname !== '/' ? 'menu-mobile__top2' : '' }`}>
        <div className="logo">
          <a href={logoUrl}>
            <img src={logo && logo.filename} alt={logo.name} />
          </a>
        </div>
        <img
          className="toggle-menu-mobile"
          src={closeMenuMobile && closeMenuMobile.filename}
          alt=""
          onClick={toggleMenu}
        />
      </div>
      <div className={`menu-mobile__middle ${location.pathname !== '/' ? 'menu-mobile__middle2' : '' }`}>
        <ul className="nav-menu-mobile">
          {Links &&
            Links.map(({ url, label, newWindow, isButton }, index) => (
              <NavLink
                key={`mobile-menu-${index}`}
                url={url}
                label={label}
                newWindow={newWindow}
                isButton={isButton}
                onClick={toggleMenu}
              />
            ))}
        </ul>
      </div>
      <div className={`menu-mobile__bottom ${location.pathname !== '/' ? 'menu-mobile__bottom2' : '' }`}>
        <h3>Contact</h3>
        <p>
          Phone: <a href={`tel:${phone}`}>{phone}</a>
        </p>
        <p>
          E-mail: <a href={`mailto:${email}`}>{email}</a>
        </p>
        <h3>Office</h3>
        <p>{address}</p>
        <p>{postalCode}</p>
        <p>{country}</p>
        <div className="social-medias">
          {socialMedias.map((item, index) => (
            <div key={`social-media-${index}`}>
              <a
                href={formatUrl(item.link)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.image.filename} alt="" />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="white-space"></div>
    </div>
  );
};

export default MenuMobile;
