import React, { useState, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import Storyblok from "./storyblok"; // Certifique-se de que isso está correto
import Layout from "./components/Layout.js";
import './app.scss';
import { Helmet } from "react-helmet";

function AppContent() {
  const location = useLocation();
  const slug = location.pathname === "/" ? "home" : location.pathname.replace("/", "");
  
  const story = useStoryblok(slug, { version: "draft" });
  const dataFooterContent = useStoryblok('globalcontent/footercontent', { version: "draft" });
  const dataHeaderContent = useStoryblok('globalcontent/headercontent', { version: "draft" });

  const formatSlug = (slug) => {
    return slug
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (!story || !story.content) {
    return <div>Loading...</div>;
  }

  const formattedSlug = formatSlug(slug);

  return (
    <>
      <Helmet>
        <title>{`Hebbink - ${formattedSlug}`}</title>
        <meta name="description" content="Providing tailored solutions to help you find your ideal home."/>
        <meta name="keywords" content="hebbink, investment,lisbon, real estate" />
        <link rel="stylesheet" href="https://use.typekit.net/kku7mqy.css" />
    <meta name="author" content="Renato Magnus da Rosa, https://www.linkedin.com/in/renato-magnus/" />
    <meta name="robots" content="index, follow" />
    <meta name="og:title" content="Hebbink" />
    <meta name="og:description" content="Providing tailored solutions to help you find your ideal home." />
    <meta name="og:type" content="website" />
    <meta name="og:image" content="%PUBLIC_URL%/logo.png" />
    <meta name="og:url" content="http://descomplicaempreendedor.com" />
    <meta name="twitter:card" content="" />
    <meta name="twitter:title" content="Hebbink" />
    <meta name="twitter:description" content="Providing tailored solutions to help you find your ideal home." />
    <meta name="twitter:image" content="%PUBLIC_URL%/logo.png" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo.png" />
      </Helmet>
      <Layout footer={dataFooterContent} header={dataHeaderContent}>
        <StoryblokComponent blok={story.content} />
      </Layout>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
