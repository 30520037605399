import { useRef, useEffect, useState } from "react";

const Slider = ({ imageGallery, gallerySubtitle }) => {
  const [opacities, setOpacities] = useState([
    1,
    ...imageGallery.slice(1).map(() => 0.4),
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % imageGallery.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + imageGallery.length) % imageGallery.length
    );
  };

  const handleScroll = () => {
    const slider = sliderRef.current;
    const sliderCenter = slider.scrollLeft + slider.clientWidth / 2;

    const newOpacities = imageGallery.map((_, index) => {
      const imageElement = sliderRef.current.querySelectorAll(
        ".slider-mobile__image"
      )[index];
      const imageCenter =
        imageElement.offsetLeft + imageElement.clientWidth / 2;
      const distance = Math.abs(sliderCenter - imageCenter);

      const opacity = Math.max(0.4, 1 - distance / (slider.clientWidth / 2));
      return opacity;
    });

    setOpacities(newOpacities);
  };

  const openModal = index => {
    setModalImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const nextModalImage = () => {
    setModalImageIndex((modalImageIndex + 1) % imageGallery.length);
  };

  const prevModalImage = () => {
    setModalImageIndex(
      (modalImageIndex - 1 + imageGallery.length) % imageGallery.length
    );
  };

  useEffect(() => {
    const slider = sliderRef.current;
    slider.addEventListener("scroll", handleScroll);

    return () => slider.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="slider__wrapper">
      <div className="title__wrapper">
        <h2 className="title">Image Gallery</h2>
      </div>
      <div className="slider">
        {imageGallery.map((item, index) => (
          <img
            key={index}
            className="side-img-slider"
            src={item.image.filename}
            alt={`Slide ${index + 1}`}
            style={{
              display:
                currentIndex === (index + 1) % imageGallery.length
                  ? "block"
                  : "none",
            }}
          />
        ))}

        <button onClick={prevImage} className="button-1">
          &lt;
        </button>

        {imageGallery.map((item, index) => (
          <img
            key={index}
            className="img-slider"
            src={item.image.filename}
            alt={`Slide ${index + 1}`}
            style={{
              display: currentIndex === index ? "block" : "none",
            }}
            onClick={() => openModal(index)}
          />
        ))}

        <button onClick={nextImage} className="button-2">
          &gt;
        </button>

        {imageGallery.map((item, index) => (
          <img
            key={index}
            className="side-img-slider"
            src={item.image.filename}
            alt={`Slide ${index + 1}`}
            style={{
              display:
                currentIndex ===
                (index - 1 + imageGallery.length) % imageGallery.length
                  ? "block"
                  : "none",
            }}
          />
        ))}
      </div>

      <div className="slider-mobile" ref={sliderRef}>
        <div className="slider-mobile__wrapper">
          {imageGallery.map((item, index) => (
            <img
              key={index}
              className="slider-mobile__image"
              src={item.image.filename}
              alt={`Slide ${index + 1}`}
              style={{
                opacity: opacities[index],
                transition: "opacity 0.3s ease",
              }}
              onClick={() => openModal(index)}
            />
          ))}
        </div>
      </div>

      <div className="slider__bottom--wrapper">
        <div className="slider__bottom">
          <p>{gallerySubtitle}</p>
        </div>
      </div>
      {isModalOpen && (
  <div className="modal-overlay" onClick={closeModal}>
    <div className="modal-content" onClick={e => e.stopPropagation()}>
      <button className="modal-button" onClick={prevModalImage}>
        &lt;
      </button>
      <img
        src={imageGallery[modalImageIndex].image.filename}
        alt={`Modal Slide ${modalImageIndex + 1}`}
        className="modal-image"
      />
      <p className="modal-counter">
        Showing {modalImageIndex + 1} of {imageGallery.length}
      </p>
      <button className="modal-button" onClick={nextModalImage}>
        &gt;
      </button>
      <img
        className="modal-close"
        src="/close.png"
        alt="Close images"
        onClick={closeModal}
      />
    </div>
  </div>
)}
    </div>
  );
};

export default Slider;
